input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.amount_input {
	background: white !important;
	cursor: default;
}

.action_button {
 	margin: 2px !important;       
}

input:disabled {
	background: white !important;
}