.coupon-details {
    /* border: 1px solid #ccc; */
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 6px;
    /* background: linear-gradient(to bottom right, #4286f4, #ffffff); */
    background-color: #f5f5f5;
  }
  
  .coupon-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 10px; */
  }
  
  .coupon-code {
    font-weight: bold;
  }
  
  .edit-status {
    display: flex;
    align-items: center;
  }
  
  .edit-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .status {
    font-weight: bold;
  }
  
  .summary-info {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
  }
  
  .expanded-details {
    margin-bottom: 10px;
  }
  
  .expand-button {
    background-color: #f5f5f5;
    color: #333;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  