.infurnia_collapse_header{
    background-color: #f5f6f8;
	padding: 8px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	/* border-bottom: 1px solid #E4E7EB; */
	font-weight: 600;
	font-size: 12px;
}

.infurnia_collapse_container{
    background-color: white;
	font-size: 12px;
	height: 0px;
	overflow: hidden;
	transition: max-height 0.5s ease-in-out;
	max-height: 0px;
}

.infurnia_collapse_container.uncollapsed{
	border: 1px solid #E6E9EE;
	height: auto;
	max-height: 10000px;
	/* transition-delay: 0.1s; */
}

.infurnia_collapse_content{
	padding: 10px 20px;
}