.activity_type_div{
	height: 40px;
	min-width: 40px;
	box-shadow: 0 0 1px 2px rgb(0 0 0/5%),0 1px 2px -2px rgb(0 0 0/6%),0 1px 3px 0 rgb(0 0 0/7%);
	border-radius: 50%;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #65686f;
	position: relative;
	z-index: 2;
}

.vertical_line:not(:last-child){
	height: calc(100% + 24px);
	width: 2px;
	border-left: 2px dashed rgba(33,35,44,.12);
	position: absolute;
	left: 18px;
	z-index: 1;
	display: flex;
}

.activity_box{
	min-height: 64px;
	/* width: 100%; */
	/* max-width: 600px; */
	flex:1;
	min-width: 0px;
	padding: 6px 16px;
	box-shadow: 0 0 1px 2px rgb(0 0 0/5%),0 1px 2px -2px rgb(0 0 0/6%),0 1px 3px 0 rgb(0 0 0/7%);
	background-color: white;
	border-radius: 6px;
	font-size: 12px;
	/* max-width: calc(100% - 94px) */
}

.org_notes_textarea{
	border: 0;
	box-shadow: 0 0 1px 2px rgb(0 0 0/5%),0 1px 2px -2px rgb(0 0 0/6%),0 1px 3px 0 rgb(0 0 0/7%);
	padding: 8px 16px;
	border-radius: 6px;
	width: 100%;
	min-height: 100px;
	font-size: 13px;
	background-color: #faf1e4;
}

@media(min-width:768px){
	.org_notes_textarea{
		margin-top:46px;
		/* margin-left: 16px; */
	}
}