.th_style {
    flex:1;
    height:40px;
    padding-left: 20px;
    font-size: 12px;
    font-weight: unset;
    border-right: 1px solid #f2f4f6;
    color:#627386;
	overflow: auto;
	min-width: 162px;
  }

.td_style {
    flex:1;
    height:40px;
    padding: 0px 0px 0px 20px;
    font-size: 14px;
    color:#22272e;
    width:100%;
    overflow-x:auto;
    white-space: nowrap;
    text-transform:capitalize;
	min-width: 162px
  }

.tr_style {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* box-shadow: inset 0 -1px 0 0 #F2F4F6; */
    border-bottom: 1px solid #F2F4F6;
}

  .flex_property{
    display: flex;
    align-items: center;
  }

  .flex_center{
    display: flex;
    align-items: center;
	justify-content: center;
  }

  .flex_between{
	display: flex;
    align-items: center;
	justify-content: space-between;
  }

  .hide{
    display: none;
    cursor:pointer;
  }
  
  .invisible1{
    visibility: hidden;
  }
  
  .display_onhover:hover .invisible1{
    display:flex;
    visibility:visible;
    color: #1890ff;
  }
  
  .display_onhover:hover{
    box-shadow: inset 0 -1px 0 0 #F2F4F6;
    background-color: rgba(24, 144, 255, 0.06) !important;
  }
  
  .underline_onhover:hover {
      text-decoration: underline;
    cursor: pointer;
  }

  .rdt1 {
    width:220px;
    /* height:50px */
  }
  @media (min-width: 768px) {
    .modal-xl {
      width: 90%;
     max-width:1200px;
    }
  }

  ::-webkit-scrollbar {
    height: 0px;
    width: 2px;
  }

  .cp{
    cursor: pointer;
  }

  .purple_border {
	padding: 6px 10px;
    display: flex;
    justify-content: center;
    border: 1px solid rgb(94, 114, 228);
    border-radius: 8px;
    color: rgb(94, 114, 228);
    cursor: pointer;
    font-size: 14px;
  }

  .rdt1 .form-control{
	padding: 4px 12px;
	width: 200px;
	height: 40px;
  }	

  .standard_input{
	width: 100%;
	height: 32px;
	font-size: 14px;
  }

  .fa-sort:hover{
	color: #0078ff;
	cursor: pointer;
  }

  .table-responsive{
	height: 100%;
  }

  .table-responsive::-webkit-scrollbar{
	height: 4px !important;
	width: 0px;
  }
  
  .table-responsive::-webkit-scrollbar-track {
	background-color: #f1f1f1; /* Color of the scroll bar track */
  }
  
  .table-responsive::-webkit-scrollbar-thumb {
	background-color: #888; /* Color of the scroll bar thumb */
  }
  
  .table-responsive::-webkit-scrollbar-thumb:hover {
	background-color: #555; /* Color of the scroll bar thumb on hover */
  }

  .find_orgs_by_email{
	color: white;
	padding: 8px 16px;
	border: 1px solid white;
	font-size: 14px;
	border-radius: 50px;
	cursor: pointer;
  }

  .calender_dot{
	width:12px;
	height:12px;
	border-radius: 100px;
  }

  .lines1_elipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blue_on_hover:hover{
	color: #0078ff;
}

.custom_navbar{
	height: 40px;
	font-size: 14px;
	display: flex;
	align-items: center;
}

.custom_navbar .nav-item{
	padding: 6px 8px;
	background-color: white;
	color: black;
	margin-right: 12px;
	border-radius: 6px;
	cursor: pointer;
}

.custom_navbar .nav-item:hover{
	background-color: rgb(33 35 44/5%);
	/* color: #3a8bf6; */
}

.custom_navbar .nav-item.selected{
	background-color: #3a8bf6;
	color: white;
}

.custom_time_filter{
	height: 32px;
	font-size: 12px;
	display: flex;
	align-items: center;
}

.custom_time_filter .nav-item{
	padding: 6px 8px;
	background-color: white;
	color: black;
	margin-right: 2px;
	border-radius: 6px;
	cursor: pointer;
}

.custom_time_filter .nav-item:hover{
	background-color: rgb(33 35 44/5%);
	/* color: #3a8bf6; */
}

.custom_time_filter .nav-item.selected{
	background-color: #eff6ff;
	color: #0d68c5;
}

.css-b62m3t-container{
	z-index: 20 !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.amount_input {
	background: white !important;
	cursor: default;
}

.action_button {
 	margin: 2px !important;       
}

.pagination {
	margin-bottom: 0px
}
.activity_type{
	padding: 6px 12px;
}

.activity_type:hover{
	background-color: #ececec ;
}

.activity_type.active{
	background-color: #daeaff;
	color: #0078ff;
	border-radius: 4px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
	padding: 9px !important;
	min-width: 180px !important;
	font-size: 14px !important;
	background-color: white !important;
}

textarea::placeholder {
	opacity: 0.4; /* Adjust the opacity as needed */
}

.bold{
	font-weight: 700;
}

.ant-upload-wrapper{
	display: block;
	margin-bottom: 24px;
}

.avatar_div{
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius:50%;
	height:32px;
	width:35px;
	background-color:orange;
	font-size:16px
}

.ticket_div{
	transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;;
}

.ticket_div:hover{
	/* transform: scale(1.01);
	box-shadow: 0 0 1px 2px rgba(88, 137, 216, 0.05),0 1px 2px -2px rgba(178, 31, 31, 0.06),0 1px 3px 0 rgba(211, 45, 45, 0.07);
	cursor: pointer; */
}

.MuiInputBase-input{
	padding: 10px !important;
}

.tickets_title:hover{
	text-decoration: underline;
}

.priority_rating {
    color: #ef461d;
    /* background-color: linear-gradient(#ff0000, #fdcf58); */
    /* background-image: linear-gradient(#ff0000, #fdcf58); */
}

body {
    overscroll-behavior-x: contain;
}

.PhoneInputInput {
    height: 40px;
}

.zIndex_100004 {
    z-index: 100004 !important;
}

.zIndex_100003 {
    z-index: 100003 !important;
}

.zIndex_100002 {
    z-index: 100002 !important;
}