.inf_model_outer_div{
    background-color: black;
    opacity: 0.05;
    z-index:5;
    height:100vh;
    width:100vw;
    position: fixed;
    top:0px;
    left:0px;
}

.badge_container{
    font-size:12px;
    width:100%;
    height:32px;
    position:relative;
    color: rgb(67, 90, 111);
    border-radius: 3px;
    border: 1px solid #ced4da;
    background-color:white;
    display:flex;
    align-items:center;
    overflow:auto
}

.options_search_container{
    z-index:7;
    background-color:white;
    border: 1px solid #ced4da;
    position:absolute;
    max-width:100%
}

.options_container{
    width:100%;
    max-height:150px;
    overflow:auto;
    font-size: 12px;
}

.single_option{
    display:flex;
    align-items:center;
    height:30px;
    font-size:"12px";
    padding:5px 10px;
    cursor:pointer 
}

.option_text{
    max-width:300px;
    overflow:auto;
    white-space:nowrap;
    text-transform:capitalize;
}

.single_badge{
    display:flex;
    align-items:center;
    font-size:"12px";
    margin:0px 10px;
    padding:0px 5px;
    border-radius:5px;
    background-color:#f2f2f2;
    max-width: 75%;
}
.badge_text{
    /* max-width:80px; */
    overflow:auto;
    white-space:nowrap;
    margin-right:5px;
    text-transform:capitalize;
    pointer-events: auto;
}

.badge_text:hover {
    pointer-events: none;
}

.search_input_box{
    width:100%;
    border:0;
    font-size:12px;
    padding:5px 10px;
    height:32px 
}