.infurnia-table {
	--light-border-color: #E6E9EE;
    width: fit-content;
    border-collapse: collapse;
}

.infurnia-table th,
.infurnia-table td {
    padding: 12px 18px;
}

.infurnia-table-row.error_in_infurnia_table_row {
    background-color: #ffe6e6 !important;
}

.infurnia-table th {
    /* background-color: #f2f2f2; */
    font-weight: 500;
    text-align: left;
    color: #A7A8B2;
}

.infurnia-table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.infurnia-table-bordered {
    border: 1px solid #ccc;
}

.infurnia-table-dark {
    color: #fff;
    background-color: #343a40;
}

.infurnia-table-dark th,
.infurnia-table-dark td {
    border-color: #454d55;
}

.infurnia-table-hover tbody tr:hover {
    background-color: #e6f7ff;
}

.table-responsive {
    overflow-x: auto;
}

.infurnia-table-bordered .infurnia-table-row:not(:last-child) {
    border-bottom: 1px solid var(--light-border-color);
}

.infurnia-table-row-separated .infurnia-table-row:not(:last-child) {
    border-bottom: 1px solid var(--light-border-color);
}

.infurnia-table-bordered .infurnia-table-cell:not(:last-child) {
    border-right: 1px solid var(--light-border-color);
}

.infurnia-table-bordered .infurnia-table-header:not(:last-child) {
    border-right: 1px solid var(--light-border-color);
}

.infurnia-table-column-separated .infurnia-table-cell:not(:last-child) {
    border-right: 1px solid var(--light-border-color);
}

.infurnia-table-column-separated .infurnia-table-header:not(:last-child) {
    border-right: 1px solid var(--light-border-color);
}

.infurnia-table-header-row {
    border-bottom: 1px solid var(--light-border-color);
}

.infurnia-table.infurnia_resizable_table{
	display: grid;
  	grid-template-columns:
    minmax(150px, 3fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr);
}

.infurnia-table.infurnia_resizable_table thead,
.infurnia-table.infurnia_resizable_table tbody,
.infurnia-table.infurnia_resizable_table tr {
	display: contents;
}

.infurnia-table.infurnia_resizable_table tr th {
	position: relative;
	border-bottom: 1px solid var(--light-border-color);
	border-right: 1px solid var(--light-border-color);
	border-top: 1px solid var(--light-border-color);
	font-weight: 700;
}

.infurnia-table.infurnia_resizable_table tr td {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	border-bottom: 1px solid var(--light-border-color);
	border-right: 1px solid var(--light-border-color);
	height: 45px;
}

.infurnia-table-container::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}

.infurnia-table-container::-webkit-scrollbar-track{
    background-color: #E6E9EE;
    box-shadow: none;
}

/* .infurnia-table-container::-webkit-scrollbar-corner{
    background-color: #E6E9EE;
} */

.infurnia-table-container::-webkit-scrollbar-thumb{
    background-color: #A7A8B2;
    border-radius: 4px;
}

.infurnia-table-container::-webkit-scrollbar-thumb:hover{
    background-color: #676878;
}

.resize-handle {
	display: block;
	position: absolute;
	cursor: col-resize;
	width: 7px;
	right: 0;
	top: 0;
	z-index: 1;
	border-right: 2px solid transparent;
  }
  
  .resize-handle:hover {
	border-color: #ccc;
  }
  
  .resize-handle.active {
	border-color: #517ea5;
  }
  